.boxModal-inner {
  background: white;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
  border-radius: 4px;
  min-width: 600px;
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
}

.boxError {
  position: absolute;
  top: 280px;
  right: 50%;
  transform: translatex(50%);
  color: red;
}

.boxBody {
  padding: 10px 20px;
  h3{
    cursor: pointer;
  }
}

.boxHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.boxFooter {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(243, 240, 240);
  margin-top: 30px;
}

.boxBody-content {
  @media screen and (max-height: 800px) {
    max-height: 500px;
    overflow: auto;
  }
  @media screen and (max-height: 700px) {
    max-height: 300px;
    overflow: auto;
  }
  @media screen and (max-height: 490px) {
    max-height: 150px;
    overflow: auto;
  }
}

.boxBody-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin-right: 30px;
  }
}

.boxBody-row-item {
  display: block;
  min-width: 200px;
  height: 40px;
  .MuiSelect-selectMenu {
    font-size: 16px !important;
  }
  & > span {
    display: block;
    font-size: 14px;
  }
  & > div {
    width: 100%;
  }
}
